.detail__container{
    display: flex;
    justify-content: space-between;
    margin: 2% 5%;
}
/*Mobile*/
@media screen and (max-width: 768px) {
    .detail__container{
        flex-direction: column;
    }
}
/*Tablette*/
@media screen and (min-width: 768px) and (max-width: 900px) {

}