.cour__section{
    display: flex;
    flex-direction: column;
    justify-self: center;
    background-color: rgba(21, 28, 29, 0.781);
    background-image: url("../images/teach.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 -295px;
    background-blend-mode: overlay;
    padding: 2% 0;
    }
    .cour__section::before{
       
        background-size: 500px;
    }
    .cour__section__head i{
        color: #ffffff;
        margin-bottom: 1.5%;
    }
.headline{
    font-family: "Montserrat";
    font-weight: 500;
    color: #fcfcfc;
    font-size: 1rem;
    margin-bottom: 3%;
}
.cour__section__card{
    display: flex;
    overflow-x: scroll;
}
.cour__section__card::-webkit-scrollbar{
    display: none;
}


@media screen and (max-width:768px)  {
    .cour__section__card{
       min-height: 250px;
       padding: 1%;
    }
}
@media screen and (min-width:768px) and (max-width:968px){
    .cour__section__card{
        min-width: 11vw;
    }
} 

