footer{
    text-align: center;
    background-color: #fff;
    font-family: "poppins";
    margin-top: 3%;
    position: relative;
    width: 100%;
    bottom: 0;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgb(226, 226, 226);
    background-color: rgb(17, 43, 54);
    color: white;
    
}
footer .social_media{
    padding-top: 1%;
}
footer .social_media i{
    margin-left: 10px;
    font-size: 20px;
}

footer p{
    width: 95%;
    text-align: center;
    margin-bottom: 0;
    padding: 0.5% 0;
    color: #ffff;
}
footer p span{
    color:#fca93d;
    font-size: 19px;
}

@media screen and (max-width:768px) {
    footer p,footer p span{
        font-size: 16px !important;
    }
}
