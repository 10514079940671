.card-container{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 30px;
    margin: 3% 5%;
}
.card-course{
    background: white;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    box-shadow: 2px 3px 19px #dbdbdb;
}
.card-bottom{
    padding: 0.625rem;
}
.card-imge{
    overflow: hidden;
    height: 300px;
}
.card-imge img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}
.fa-award,.fa-history{
    margin-right: 8px;
}
 .card-bottom-l1,.card-bottom-l3{
    display: flex;
    justify-content: space-between;
    margin:0.5rem 0;
}
.card-bottom hr{
    margin:0 !important;
}

.card-bottom i{
    font-size: 22px;
}

.card-bottom p{
    color: #034161;
}
.matiere{
    font-weight: 600;
    font-size: 14px;
}
.exp{
    color: #033C5A;
}
.details{
    border:none;
    background-color: #FFA200;
    color: #ffffff;
    border-radius: 20px;
    font-family: "poppins";
    font-size: 15px;
    padding: 5px 7px;
    text-decoration: none;
}
.details:hover{
    color: white;
}
.honoraire{

}
.nom{
    font-weight: 900;
    font-size: 20px;
    text-align: left;
    margin:0.5rem 0;
}

@media screen and (max-width:768px) {
    .card-container{
        grid-template-columns: repeat(1,1fr);
    }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
    .card-container{
        grid-template-columns: repeat(2,1fr);
    }
}