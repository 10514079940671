.valor__card{
    border-radius: 10px;
    background-color: #edf2f6;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 300px;
    height: 200px;
    }
.valor__card .icon{
    padding: 4%;
    width: 50px;
    height: 50px;
    color: rgb(255, 255, 255);
    background: #fcb928e7;
    border-radius: 50px;
}
.valor__card .icon i .fas{
    line-height: 29px !important;
}
.valor__card .headline h6  {
    font-size: 1.2rem;
    margin:0;
    padding: 3%;
}
.valor__card .headline p{
    font-family: "poppins";
    padding: 1% 5%;
    color: #505050;
    font-weight: 400;
}
/*RESPONSIVE DESIGN*/
/*tablette*/
@media screen  and (min-width:768px) and (max-width:900px){
    
 }
 
 /*mobile*/
 @media screen  and (max-width:768px){
     .valor__card{
        margin-bottom: 5%;
        margin-right: 2%;
     }
  }