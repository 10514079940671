.cours_card{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 12.41vw;
    min-height: 35.02vh;
    background-color: #ffff;
    border-radius: 15px;
    padding: 0 15px;
    padding-top: 10px ;
    margin-bottom: 3%;
    margin-left: 1%;
    border: 1px solid rgba(252, 184, 59, 0); 
    transition: all 0.2s ease-in;
}
.cours_card:hover {
    border: 1px solid #ffa600; 
}
.cours_card h3{
    text-align: left;
    margin: 0;
    font-size: 1.5rem;
    color: rgb(80, 59, 23);
    font-weight: bold;
   
}
.cours_card .list {
    text-align: left;
    text-decoration: none;
    list-style: none;
    padding: 0;
    margin-top: 2%;
    transition: all 0.3s ease-in;
}
.cours_card .list:hover{
    margin-left: 10px;
}
.cours_card .list > li > a{
    text-decoration: none;
    color: black;
    transition: all 0.3s ease-in;
}
.cours_card .list li{
    margin-bottom: 20px;
}
.cours_card .list>li>a:hover{
   color: rgb(252, 127, 45);
}
/*Tablette*/
@media screen and (min-width:768px) and (max-width:968px){
    .cours_card{
        min-width: 35vw;
        min-height: 35vh;
        margin-right: 20px;
    }
}
/*Mobile*/
@media screen and (max-width:420px)  {
    .cours_card{
        min-width: 48vw;
        min-height: 35vh;
        margin-right: 10px;
    }
    .cours_card h3{
        font-size: 1rem !important;
       margin-bottom: 10px;
    }
}
