.details__section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2%;
  padding-top: 5%;
  background-color: #f8f8f8 ;
}
.prof__image {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 200px;
  background-color: black;
  overflow: hidden;
}
.prof__image img {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in;
  opacity: 0.7;
}
.prof__image img:hover {
  transform: scale(1.08);
  opacity: 1;
  cursor: pointer;
}

.prof__info {
  position: relative;
  text-align: left;
  margin-left: 60px;
  line-height: 35px;
}
.prof__info h1 {
  margin: 0;
  margin: 2%;
  font-size: 2rem;
  font-weight:700;
  text-align: center;
}
.prof__info p {
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 2.5%;
}
.prof__info button {
  border: none;
  background-color: #f29e4a;
  color: #ffff;
  font-family: "Montserrat semiBold";
  padding: 4%;
  border-radius: 50px;
  font-size: 1.5rem;
}
.avis__icone {
  color: rgb(250, 172, 28);
  margin-bottom: 3.5%;
  font-size: 1rem;
}
.avis__icone i {
  margin-right: 1%;
}

/*Mobile*/
@media screen and (max-width: 768px) {
  .details__section {
    flex-direction: column;
    padding-top: 20%;
  }
  .prof__info {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .avis__icone {
    width: 100%;
    text-align: center;
  }
}
/*Tablette*/
@media screen and (min-width: 768px) and (max-width: 900px) {
  .details__section {
    padding-top: 10%;
  }
  .prof__info h1{
    font-size: 3rem;
  }
}
