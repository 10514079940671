.about__section{
    position: relative;
    margin-top: 5%;  
}
h5, span{
    font-size: 1.5rem;
    margin: 0 1%;
}
span{
    color: #e27f14de;
}
.about__section  p{
    text-align: center justify;
    margin: 1.7% 30%;
    color: 063843;
    font-size: 1.1rem;
}
.separator{
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    border: dotted #b3a98d 6px;
    border-bottom: none;
    width: 10%;
}
/*RESPONSIVE DESIGN*/
/*Tablette*/
@media screen and (min-width:700px) and (max-width:900px) {
    .about__section p{
        margin: 2.5% 10%;
        text-align: center;
    }
    
}
@media screen and (max-width:700px) {
    .about__section p{
        margin:5%;
        font-size: 0.9rem;
    }
    .separator{
        width: 20%;
    }
    .about__section{
        margin-top: 10%;
    }
}