.sidebar{
    margin:0;
    text-align: left;
    width: 25%;
    background-color: orange;
    min-height: 100vh;
    color: #ffff;
}
.sidebar__top{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    margin:2% 5%;
    border-bottom: 1px solid #ffff;
}
.sidebar__top h2 span.plus{
    font-family: "Montserrat Thin";
    color: #ffff;
}
.sidebar__link ul li{
    color: white;
    list-style: none;
    padding: 5% 8%;
    transition: all 0.25s ease;
    
}
.sidebar__link{
    margin-top: 10%;
}
.sidebar__link ul {
    padding:0;
}
.sidebar__link ul li:hover{
    background-color: rgb(233, 152, 3);
    border-left: 5px solid #ffff;
}
.sidebar__link ul li a{
    text-decoration: none;
    color: #ffff;
    font-family: "poppins";
    font-size: 20px;
}
.sidebar__link ul li i{
    margin-right: 10px;
    font-size: 25px;
}