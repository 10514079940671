.admin-content{
    width: 100%;
}
.prof-content>h4{
    padding:1%;
    background-color: rgba(18, 52, 63, 0.767);
    margin-bottom: 2%;
    border-radius: 5px;
    width: 30%;
    text-align: center;
    color: #ffff;
    text-transform: uppercase;
    font-size: 20px;
}
.personal__info{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
}
.prof-content{
    margin:3%;
}
.generalB{
    border: 1px solid #c0c0c0a4;
    border-radius: 6px;
}
.profile{
    padding: 20px 30px;
    text-align: left;
}
.profile input#input-file{
   display: none;
}
.profile label[for="input-file"]{
    background-color: rgb(255, 166, 0);
    padding:1%;
    color: #ffff;
    border-radius: 5px;
    cursor: pointer;
}
.profile label[for="input-file"]:hover{
    background-color: rgb(245, 180, 59);
}
.profile label[for="input-file"]:active{
    background-color: rgb(182, 119, 2);
}
label[name=file-choosen]{
    padding-left: 1%;
}
.botumValidate{
    padding: 12px;
    border-radius: 14px;
    width: 13%;
    font-size: 1.2rem;
    color: rgba(4, 65, 65, 0.788);
    margin-left: 5%;
    margin-bottom: 10px;
    border:1px solid rgba(4, 65, 65, 0.788);
    background: none;
    transition: all 0.25s ease-in;
}
.botumValidate:hover{
    background-color: rgba(4, 65, 65, 0.788);
    border:none;
    color:#ffff;
}

.profosional_info{
    border: 1px solid #ffd000a4;
    border-radius: 6px;
    margin-top: 15px;

}

.residence_info{
    border: 1px solid #c0c0c0a4;
    border-radius: 6px;
    margin: 2%;
}

.title_brand{
    width: 100%;
    text-align: left;
    background-color: rgba(4, 65, 65, 0.788);
    padding:8px 1%;
    color:#ffff;
    border-radius:6px 6px 0 0;
}

.inputSize{
    padding: 1rem 1rem;
}
.inputSize input,select {
    padding: 1rem;
    width: 100%;
    border:1px solid #0000003b;
    border-radius:5px;
    transition:all 0.25s ease-in ;
    colro:#0000;
}
.inputSize input:focus{
    border: 1px solid rgb(46, 179, 231);
    -webkit-box-shadow: -1px 0px 1px 5px rgba(57,208,155,0.55); 
     box-shadow: -1px 0px 1px 2.5px rgba(57,208,155,0.55);
    
}

.inputSize input,select::placeholder{
    color:rgba(12, 12, 12, 0.89);
}

.text__area{
    padding: 1rem 4rem;
}
.text__area textarea{
    width: 100%;
    resize: none;
    height: 200px;
    border-radius: 8px;
    border:1px solid rgb(214, 214, 214);
    padding:10px;
    transition: all 0.25s ease-in;
}
.text__area textarea:focus{
    border: 1px solid rgb(46, 179, 231);
    -webkit-box-shadow: -1px 0px 1px 5px rgba(57,208,155,0.55); 
     box-shadow: -1px 0px 1px 2.5px rgba(57,208,155,0.55);
}
.progress{
    background-color: rgba(18, 219, 152, 0.952);
    border-radius: 50px;
}