.place__section{
    display: flex;
    justify-content: space-around;
    margin:5%;
}
.place__right__side{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}
.place__left__side{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    border-right: 1px solid #767676;
}
.place__left__side p{
    width: 70%;
    font-size: 16px;
}
.place__left__side h3{
    font-weight: 700 ;
    font-size: 30px;
    margin-top:0;
    margin-bottom: 0;
}
.place__left__side span{
    font-size: 22px;
}
.place__right__side a{
   padding:10px;
   text-decoration: none;
   color: rgb(51, 51, 51);
   border:1px solid #c4c4c4;
   width: 100%;
   margin-bottom: 3%;
   border-radius: 5px;
   transition: all 0.2s ease-in;
   font-weight: bold;
}
.place__right__side a:hover{
    background-color: #ffa500;
    color:#ffff;
    border:none;
    transform: scale(1.02);
}

/*RESPONSIVE DESIGN*/
/*Tablette*/
@media screen and (min-width:768px) and (max-width:900px) {
    .place__section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin:5% !important;
    }
    .place__left__side{
        border-right: none;
        text-align: center;
    }
    .place__left__side p{
        text-align: center;
        width: 100%;
    }
    .place__right__side {
        flex-direction: row;
        box-sizing: border-box;
        margin:0 5%;
        width: auto;
        justify-content: space-around;
    }
    .place__right__side a{
        border:none;
        background-color: #ffa500;
        color:#ffff;
        width: 110px;
    }
    .place__right__side a:active{
        background-color: #ffc75e;
    }
}
/*Mobile*/
@media screen and (max-width:768px){
    .place__section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10%;
    }
    .place__left__side{
        border-right: none;
        text-align: center;
    }
    .place__left__side p{
        text-align: center;
        width: 100%;
    }
    .place__right__side {
        box-sizing: border-box;
        margin:0 5%;
        width: auto;
    }
    .place__right__side a{
        border:none;
        background-color: #ffa500;
        color:#ffff;
    }
    .place__right__side a:active{
        background-color: #ffc75e;
    }
}