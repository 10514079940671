.search-box {
  border: 1px solid #ffffffab;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 15px;
  background-color: #ffffff;
  padding-top: 6%;
}
.search{
  display: flex;
  align-items: center;
}
.search i {
  margin: 0 10px;
  color: #3b3b3b;
}
.search input {
  padding: 12px 0;
  color: #000;
  border: none;
  border-right: 1px solid #858585;
}
 input[placeholder="niveau"]{
   border-right: none;
 }
input:focus{
  outline: none;
  border:none;
}

.search-box button{
  padding: 12px;
  margin-left: 15px;
  background: #f38d26;
  border-radius: 10px;
  border:none;
  color: #fff;
  border-radius: 5px;
}
.person {
  text-align: center;
  font-size: 15px;
  font-weight: 900;
  font-family: fantasy;
  margin: 30px;
}
.container {
  text-align: center;
  min-height: 45vh;
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.hideBtn{
  display:none;
}
.card {
  margin-bottom: 30px;  
}
.card__section .person{
  font-family: "Montserrat semiBold";
  font-size: 1.5rem;
  font-weight: normal;
}
.card__section p span{
  color: rgb(43, 43, 43);
  font-size: 15px;
  font-family: "poppins";
  margin-left: 2%;
}

.contain {
  margin-bottom: 50px;
}
.card-body{
  text-align: left;
}
.card-body h4{
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}
.card-body>p{
  font-size: 15px;
  text-align: left;
  color:rgb(34, 34, 34);
  margin-bottom: 0;
  text-transform: uppercase;
}
.card-body h4{
  color: rgb(20, 75, 114);
  font-size: 20px;
}
.card-body span{
  font-family: "poppins";
  color: rgb(29, 29, 29);
}
span {
  color: #f38c26e1;
}

.barreV {
  border: 1px solid #36363636;
  width: 70%;
  margin: auto;
}
.hide{
  display: none;
}

@media (min-width: 280px) and (max-width: 950px){
 .search-box{
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 21%;
   width: 75%;

 }
 .search{
   width: 90%;
   border-bottom: 1px solid #333333;
   padding:1% 0;
   z-index: 100;
 }
 .search input{
  /* margin-bottom: 14px; */
  border-right: none;
 }
 .search-box button{
   margin-top: 15px;
 }
 .card{
   width: 10rem !important;
 }
 .navbar-brand{
   margin: 5px 0 !important;
 }
}

/* @media screen and (max-width:765px){
   .search-box {
     display: flex;
     flex-direction: column;
     align-items: center;
    padding-top: 15%;
   }
   input{
    border-bottom: 1px solid #000000a6;
    margin-bottom: 14px;
    border-right: none;
   } 
} */

.loading__circle{
  display: none;
}

.loading__circle__active{
  color:rgb(8, 129, 185);
  animation: rotate 2s linear infinite ;
}

@keyframes rotate{
  0%{
      transform: rotate(0deg);
  }
  100%{
      transform: rotate(360deg);
  }

}
.filterAdd>span{
  background-color: rgba(255, 255, 255, 0.918);
  padding: 3px 9px;
  border-radius: 50px;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: left;
  color: #034f6d;
  border:1px solid #066692;
  
}
.filterAdd {
  text-align: left;
  margin-left:10%;
}

.filterAdd span i{
  margin-left: 2px;
  font-size: 15px;
  transition: all 0.25s ease-in;
}
.filterAdd span i:hover{
  color:#a52a2a;
  cursor: pointer;
}
