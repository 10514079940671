.detail__left{
   width: 23%;
   text-align: left;
}
.top{
    background-color: rgba(245, 244, 244, 0.973) ;
    padding:5%;
    border-radius: 5px;
    margin-bottom: 3%;
}
.top hr{
    color: rgb(192, 192, 192);
    opacity: 0.3;
}
.bottom hr{
    color: rgb(192, 192, 192);
    opacity: 0.3;
}
.bottom{
    background-color: rgba(245, 244, 244, 0.973);
    padding:5%;
    border-radius: 5px;
}
.detail__left h3{
    margin: 0;
}
.detail__left p{
    margin: 0;
}
 .libelle{
    display: flex;
    align-items: center;
    padding:10px;
    background-color:rgb(238, 238, 238);
    padding: 1%;
    width:60%;
    border-radius: 5px;
}
 .libelle i{
    margin-right: 10px;
}

/*Mobile*/
@media screen and (max-width:768px){
    .detail__left{
        width: 100%;
    }
    .top,.bottom{
        background-color: rgb(250, 247, 241);
    }
    .top hr, .bottom hr{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
/*Tablette*/
@media screen and (min-width:768px) and (max-width:900px) {
    .libelle {
        width: 100%;
    }
}