.detail__right{
    width: 70%;
    text-align: left;
}
.detail__right .presentation{
    background-color: #ffff;
    padding: 2% 3%;
    border-radius: 15px;
    margin-bottom: 2%;
}
.detail__right .curriculum{
    background-color: #ffff;
    padding: 2% 3%;
    border-radius: 15px;
}
.detail__right .curriculum .top-right{
    display: flex;
    align-items: center;
}
.detail__right .curriculum .top-right i{
    margin-right: 15px;
}
.video{
    display: flex;
    justify-content: center;
    text-align: center;
    margin:2% 0;
}
/*Mobile*/
@media screen and (max-width:768px){
    .detail__right{
        width: 100%;
    }
    .detail__right .presentation{
        margin-top: 10px;
    }
    .detail__right .presentation::after{
        display: inline-block;
        content: "";
        border-bottom: 1px solid rgb(230, 230, 230);
        width: 100%;
    }
    .youtube_embeded{
        width: 450px;
        height: 225px;
    }
}
/*Tablette*/
@media screen and (min-width:768px) and (max-width:900px) {
   
}