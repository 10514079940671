.completeCate{
    width: 100%;
}
.combo{
    padding: 1.1rem;
    width: 30%;
}
.labelCat{
    font-size: 1.5rem;
    text-align: left;
}
.comlab{
    display: flex;
    justify-content: space-around;
}
.errorMsg{
    margin-bottom: 0;
}